import { Colors, Theme } from '../types';
import { defaultStyles } from '../../constants';
import { getButtonColors } from './getButtonColors';

interface GetCurrentThemeArg {
  colors: Colors;
  isWebView: boolean;
  insets: unknown;
}
export const getCurrentTheme = ({
  colors,
  isWebView,
  insets
}: GetCurrentThemeArg): Theme => ({
  ...defaultStyles,
  colors,
  button: getButtonColors(colors),
  isWebView,
  webview: { insets }
});
