import Router from 'next/router';
import { BRIDGE_NO_TOP_INSET_KEY, BRIDGE_NO_TOP_INSET_REGEXP } from '../config';

export const noTopInsert = pathname => {
  if (typeof window !== 'undefined') {
    const parsedUrl = pathname
      ? new URL(`${window.location.origin}${pathname}`)
      : new URL(`${window.location.href}`);

    const isSafeArea =
      parsedUrl.searchParams.get(BRIDGE_NO_TOP_INSET_KEY) === 'false';

    if (
      BRIDGE_NO_TOP_INSET_REGEXP.some(rx => rx.test(parsedUrl.pathname)) &&
      !isSafeArea
    ) {
      parsedUrl.searchParams.set(BRIDGE_NO_TOP_INSET_KEY, 'false');

      Router.replace(parsedUrl.toString(), undefined, { shallow: true });
    }
  }
};
