export const gradient = {
  basic: 'linear-gradient(315deg, #e7e7e7 0%, #f6f7fa 100%)',
  platinum: 'linear-gradient(315deg, #e2e3ea 0%, #f0edff 100%)',
  silver: 'linear-gradient(315deg, #d6d6d6 0%, #ededed 100%)',
  gold: 'linear-gradient(320deg, #decfa7 -8.11%, #ede6d2 69.11%)',
  promo: 'linear-gradient(90deg, #00c850 0%, #52cb98 50%, #37c7bf 100%)'
};

export interface DefaultStyles {
  fontFamily: string;
  borderRadius: string;
  gradient: typeof gradient;
  baseFontSize: string;
  tabletBreakpoint: string;
  smallDesktopBreakpoint: string;
  desktopBreakpoint: string;
  mobile: { tabBar: { height: number } };
}
