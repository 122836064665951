import { gql } from '@apollo/client';

export function itemEditQuery(id) {
  const name = `ItemEditQuery_${id}`;
  return gql`
    query ${name}($itemId: ID!) {
      itemEdit(itemId: $itemId) {
        item {
          title
          isLeased
          location {
            fullName
          }
          category {
            id
          }
        }
        edit {
          area {
            units
            value
          }
          contactName
          description
          floor
          floors
          hasBillOfSale
          hasMortgage
          hasRepair
          landArea {
            units
            value
          }
          paidDaily
          photos {
            thumbnail
          }
          price {
            currency
            value
          }
          rooms
        }
      }
    }
  `;
}
