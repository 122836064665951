import { gql } from '@apollo/client';

export const categoriesQuery = gql`
  query CategoriesQuery {
    categories {
      id
      name
      pluralName
      slug
      areaUnits
      photosRequired
      areRoomsRequired
      hasRepair
      hasBuildingType
      hasFloor
      hasLandArea
      hasRooms
      children {
        id
        name
        pluralName
        slug
        photosRequired
        areaUnits
        areRoomsRequired
        hasRepair
        hasBuildingType
        hasFloor
        hasLandArea
        hasRooms
      }
    }
  }
`;

const temp = 'just for eslint';

export default temp;
