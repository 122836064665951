import { ButtonColorsMap, ButtonType, Colors } from '../types';
import { FontWeight } from '../../constants';

export const getButtonColors = ({
  background,
  systemColors,
  text
}: Colors): ButtonColorsMap => ({
  [ButtonType.default]: {
    color: background.primary,
    background: systemColors.blue
  },
  [ButtonType.edit]: {
    color: systemColors.blue,
    background: background.primary,
    border: `1px solid ${systemColors.blue}`
  },
  [ButtonType.white]: {
    color: text.primary,
    background: background.primary
  },
  [ButtonType.delete]: {
    color: systemColors.red,
    background: background.primary,
    border: `1px solid ${systemColors.red}`
  },
  [ButtonType.green]: {
    color: background.primary,
    background: systemColors.green
  },
  [ButtonType.kapitalBank]: {
    color: background.primary,
    background: systemColors.kapital,
    fontWeight: FontWeight.medium
  },
  [ButtonType.link]: {
    color: systemColors.blue,
    background: 'initial'
  },
  [ButtonType.signIn]: {
    color: systemColors.blue,
    background: systemColors.lightBlue
  }
});
