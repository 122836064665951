import { DefaultStyles, gradient } from './types';

export enum Breakpoints {
  tablet = 744,
  smallDesktop = 950,
  desktop = 1280
}

export enum FontWeight {
  thin = 400,
  medium = 500,
  bold = 700
}

export const defaultStyles: DefaultStyles = {
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  borderRadius: '14px',
  gradient,
  baseFontSize: '16px',
  tabletBreakpoint: `${Breakpoints.tablet - 1}px`,
  smallDesktopBreakpoint: `${Breakpoints.smallDesktop - 1}px`,
  desktopBreakpoint: `${Breakpoints.desktop - 1}px`,
  mobile: { tabBar: { height: 53 } }
};
