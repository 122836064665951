export const profileItemStatusCounts = `
        PUBLISHED: items(state: PUBLISHED) {
          totalCount
        }

        VALIDATED_AND_AWAITING_PAYMENT: items(
          state: VALIDATED_AND_AWAITING_PAYMENT
        ) {
          totalCount
        }

        EXPIRED: items(state: EXPIRED) {
          totalCount
        }

        PENDING: items(state: PENDING) {
          totalCount
        }

        REJECTED: items(state: REJECTED) {
          totalCount
        }
`;
