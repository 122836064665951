const getCategoriesDefaultData = lang => {
  switch (lang) {
    case 'ru':
      return [
        {
          id: '1',
          name: 'Квартира',
          pluralName: 'Квартиры',
          slug: 'menziller',
          areaUnits: 'м²',
          photosRequired: 4,
          areRoomsRequired: true,
          hasRepair: true,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: true,
          children: [
            {
              id: '2',
              name: 'Новостройка',
              pluralName: 'Новостройки',
              slug: 'menziller/yeni-tikili',
              photosRequired: 4,
              areaUnits: 'м²',
              areRoomsRequired: true,
              hasRepair: true,
              hasBuildingType: false,
              hasFloor: true,
              hasLandArea: false,
              hasRooms: true,
              __typename: 'Category'
            },
            {
              id: '3',
              name: 'Вторичка',
              pluralName: 'Вторички',
              slug: 'menziller/kohne-tikili',
              photosRequired: 4,
              areaUnits: 'м²',
              areRoomsRequired: true,
              hasRepair: true,
              hasBuildingType: false,
              hasFloor: true,
              hasLandArea: false,
              hasRooms: true,
              __typename: 'Category'
            }
          ],
          __typename: 'Category'
        },
        {
          id: '5',
          name: 'Дом/Дача',
          pluralName: 'Дома/Дачи',
          slug: 'heyet-evleri',
          areaUnits: 'м²',
          photosRequired: 4,
          areRoomsRequired: false,
          hasRepair: true,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: true,
          hasRooms: true,
          children: [],
          __typename: 'Category'
        },
        {
          id: '7',
          name: 'Офис',
          pluralName: 'Офисы',
          slug: 'ofisler',
          areaUnits: 'м²',
          photosRequired: 4,
          areRoomsRequired: true,
          hasRepair: true,
          hasBuildingType: true,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: true,
          children: [],
          __typename: 'Category'
        },
        {
          id: '8',
          name: 'Гараж',
          pluralName: 'Гаражи',
          slug: 'qarajlar',
          areaUnits: 'м²',
          photosRequired: 1,
          areRoomsRequired: false,
          hasRepair: false,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: false,
          children: [],
          __typename: 'Category'
        },
        {
          id: '9',
          name: 'Участок',
          pluralName: 'Участки',
          slug: 'torpaq',
          areaUnits: 'сот',
          photosRequired: 1,
          areRoomsRequired: false,
          hasRepair: false,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: false,
          children: [],
          __typename: 'Category'
        },
        {
          id: '10',
          name: 'Объект',
          pluralName: 'Объекты',
          slug: 'obyektler',
          areaUnits: 'м²',
          photosRequired: 4,
          areRoomsRequired: false,
          hasRepair: true,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: false,
          children: [],
          __typename: 'Category'
        }
      ];
    default:
      return [
        {
          id: '1',
          name: 'Mənzil',
          pluralName: 'Mənzillər',
          slug: 'menziller',
          areaUnits: 'm²',
          photosRequired: 4,
          areRoomsRequired: true,
          hasRepair: true,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: true,
          children: [
            {
              id: '2',
              name: 'Yeni tikili',
              pluralName: 'Yeni tikililər',
              slug: 'menziller/yeni-tikili',
              photosRequired: 4,
              areaUnits: 'm²',
              areRoomsRequired: true,
              hasRepair: true,
              hasBuildingType: false,
              hasFloor: true,
              hasLandArea: false,
              hasRooms: true,
              __typename: 'Category'
            },
            {
              id: '3',
              name: 'Köhnə tikili',
              pluralName: 'Köhnə tikililər',
              slug: 'menziller/kohne-tikili',
              photosRequired: 4,
              areaUnits: 'm²',
              areRoomsRequired: true,
              hasRepair: true,
              hasBuildingType: false,
              hasFloor: true,
              hasLandArea: false,
              hasRooms: true,
              __typename: 'Category'
            }
          ],
          __typename: 'Category'
        },
        {
          id: '5',
          name: 'Həyət evi/Bağ evi',
          pluralName: 'Həyət evləri/Bağ evləri',
          slug: 'heyet-evleri',
          areaUnits: 'm²',
          photosRequired: 4,
          areRoomsRequired: false,
          hasRepair: true,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: true,
          hasRooms: true,
          children: [],
          __typename: 'Category'
        },
        {
          id: '7',
          name: 'Ofis',
          pluralName: 'Ofislər',
          slug: 'ofisler',
          areaUnits: 'm²',
          photosRequired: 4,
          areRoomsRequired: true,
          hasRepair: true,
          hasBuildingType: true,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: true,
          children: [],
          __typename: 'Category'
        },
        {
          id: '8',
          name: 'Qaraj',
          pluralName: 'Qarajlar',
          slug: 'qarajlar',
          areaUnits: 'm²',
          photosRequired: 1,
          areRoomsRequired: false,
          hasRepair: false,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: false,
          children: [],
          __typename: 'Category'
        },
        {
          id: '9',
          name: 'Torpaq',
          pluralName: 'Torpaq',
          slug: 'torpaq',
          areaUnits: 'sot',
          photosRequired: 1,
          areRoomsRequired: false,
          hasRepair: false,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: false,
          children: [],
          __typename: 'Category'
        },
        {
          id: '10',
          name: 'Obyekt',
          pluralName: 'Obyektlər',
          slug: 'obyektler',
          areaUnits: 'm²',
          photosRequired: 4,
          areRoomsRequired: false,
          hasRepair: true,
          hasBuildingType: false,
          hasFloor: false,
          hasLandArea: false,
          hasRooms: false,
          children: [],
          __typename: 'Category'
        }
      ];
  }
};

export default getCategoriesDefaultData;
