export const EventName = Object.freeze({
  Connecting: 'connecting',
  SetConfig: 'setConfig',
  Handshake: 'handshake',
  SyncStorage: 'syncStorage',
  OpenUrl: 'openUrl',
  CloseWebview: 'closeWebview',
  OpenPayment: 'openPayment',
  Share: 'share'
});
