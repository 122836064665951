import { EventName } from './types';

export const initialState = {
  isWebView: false,
  isSetConfig: false,
  isConnected: false,
  bridgeVersion: 0,
  insets: { top: 0, bottom: 0, left: 0, right: 0 }
};

export const bridgeReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case EventName.SyncStorage: {
      return {
        ...state,
        ...payload
      };
    }
    case EventName.Connecting: {
      return {
        ...state,
        isConnected: true
      };
    }
    case EventName.SetConfig: {
      return {
        ...state,
        isSetConfig: true,
        ...payload
      };
    }
    case EventName.Handshake: {
      return {
        ...state,
        isWebView: true,
        ...payload
      };
    }
    default:
      return state;
  }
};
