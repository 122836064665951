import { gql } from '@apollo/client';

export const citiesListQuery = gql`
  query CitiesListQuery {
    cities(filter: { scope: ALL }, limit: 70) {
      id
      name
      slug
      latitude
      longitude
    }
  }
`;

export const citiesPopularListQuery = gql`
  query CitiesPopularListQuery {
    cities(filter: { scope: POPULAR }, limit: 5) {
      id
      name
      slug
    }
  }
`;

export const citiesUnPopularListQuery = gql`
  query CitiesUnPopularListQuery {
    cities(filter: { scope: UNPOPULAR }, limit: 80) {
      id
      name
      slug
    }
  }
`;
