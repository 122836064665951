import { gql } from '@apollo/client';

export function cityQuery({ id }) {
  const name = `CityQuery_${id}`;
  return gql`
    query ${name}($id: ID!) {
      city(id: $id) {
        id
        name
        slug
        latitude
        longitude
        locations {
          id
          name
          locationGroupId
        }
      }
    }
  `;
}

const temp = 'just for eslint';

export default temp;
