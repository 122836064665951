import { ReactNode, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useBridgeState } from '../../bridge';
import { lightColors } from './light';
import { darkColors } from './dark';
import { ThemeType } from './constants';
import { getCurrentTheme } from './helpers/getCurrentTheme';

interface ThemeWrapperProps {
  children: ReactNode;
}

export const ThemeWrapper = ({ children }: ThemeWrapperProps) => {
  const { isWebView, insets } = useBridgeState();

  const [themeType] = useState<ThemeType>(ThemeType.light);
  const colors = themeType === ThemeType.light ? lightColors : darkColors;
  const theme = getCurrentTheme({ colors, isWebView, insets });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
